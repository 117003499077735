import React from "react";
import BlogPostItem from "../components/BlogPostItem";
import SearchInput from "../components/SearchInput"; // Import the SearchInput component
import { usePosts } from "../context/PostsContext"; // Import the context

const RecentPostsPage: React.FC = () => {
  const { posts, loading, error } = usePosts(); // Get posts, loading, and error from context

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="pt-8">
      {/* Search input visible only on small screens */}
      <div className=" mb-4">
        <SearchInput
          placeholder="Search posts..."
          classNames={{
            input: "block sm:hidden",
          }}
        />
      </div>

      {posts.map((post: any) => (
        <BlogPostItem key={post.id} post={post} />
      ))}
    </div>
  );
};

export default RecentPostsPage;
